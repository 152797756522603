<template>
  <div class="user-head-portrait-war">
    <img v-if="imgPath" :src="imgPath" title="点击上传寸照" class="img-circle img-lg">
    <i v-else class="van-icon van-icon-photograph van-uploader__upload-icon"></i>
    <h5-cropper
      class="cropper-box"
      :option="options"
      @getblobData="getblobData"
      :canMoveBox="true" 
      :fixed="false"  
      ref="cropper">
    </h5-cropper>
  </div>
</template>

<script>
import H5Cropper from 'vue-cropper-h5'
import { fileUpload } from '@/api'
import { Overlay, Toast } from 'vant';
import { changeDpiBlob } from 'changedpi'
export default {
  
  components: {
    H5Cropper,
    [Overlay.name]: Overlay,
    [Toast.name]: Toast
   },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 弹出层标题
      options: {
        img: '', // 裁剪图片的地址
        // autoCrop: true, // 是否默认生成截图框
        canMove: true,
        info: true,
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 420,
        outputSize: 0.1,
        fixedNumber: [1,1.40],
        enlarge: 1,
        // fixedBox: true, // 固定截图框大小 不允许改变
      }
    }
  },
  computed: {
    imgPath() {
      if (this.value) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.options.img = 'http://qgyskj.cn/art/file/preview?fileId=' + this.value
          return this.options.img
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.options.img = location.origin + '/art/file/preview?fileId=' + this.value
          return this.options.img
        }
      }
      return ''
    }
  },
  methods: {
    resizeImage(file, newWidth, newHeight, callback) {
      // 创建FileReader读取图片
      const reader = new FileReader()
      reader.onload = function (event) {
        const img = new Image()
        img.src = event.target.result
        img.onload = function () {
          // 创建canvas并绘制调整大小后的图片
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = newWidth; 
          canvas.height = newHeight;
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
    
          // 将canvas转换为blob数据
          canvas.toBlob(function (blob) {
            changeDpiBlob(blob, 30).then(newBlob => {
              callback(newBlob);
            })
          }, 'image/jpeg', 0.98);
        };
      }
      reader.readAsDataURL(file);
    },
    getblobData(data) {
      this.resizeImage(data, 413, 579, (file) => {
        const formData = new FormData()
        formData.append('file', file)
        Toast.loading({
          message: '加载中...',
          duration: 0,
          forbidClick: true,
        });
        fileUpload(formData).then(response => {
          Toast.clear()
          Toast.success('上传成功！')
          this.$emit('change', response.resultData.id)
          this.options.img = this.imgPath
        }).catch(() => {
          Toast.clear()
          Toast.fail('上传失败！')
        })
      })
      return
      
    }
  }
}
</script>

<style lang="less" scoped>
.user-head-portrait-war {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  background-color: #f7f8fa;
}

.cropper-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.van-uploader__upload-icon {
  font-size: 80px;
}

// .user-head-portrait-war:hover:after {
//   content: '+';
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 10px;
//   color: #eee;
//   background: rgba(0, 0, 0, 0.5);
//   font-size: 24px;
//   font-style: normal;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   cursor: pointer;
//   line-height: 110px;
//   border-radius: 5px;
//   text-align: center;
//   font-size: 40px;
// }

.img-circle {
	border-radius: 5px;
}

.img-lg {
  // 413 x 626 579
	width: 138px;
	// height: 209px;
}
</style>
