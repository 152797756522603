<!-- 报名资料填写 -->
<template>
  <div class="main-box-war">
    <div class="text-list-war" >
      <div class="head">
        <img :src="require('@/assets/logo.png')" height="35px" class="head-img" alt="">{{ userName }}
      </div>
      <!-- <van-collapse v-model="active">
        <van-collapse-item  title="报名时间："  :value="applyTime" name="1">
          描述：{{ periodDesc }}
        </van-collapse-item>
      </van-collapse> -->

      <van-form ref="vanForm" @submit="onSubmit" colon :show-error-message="false" label-align="left" title="填写报名信息">
        <van-field
          v-model="studName"
          name="studName"
          placeholder="请输入考生姓名"
          clearable
          :rules="[{ required: true }]"
          @blur="domainConvert('1')"
        >
          <template slot="label">
            <i class="reqire">*</i> 考生姓名
          </template>
        </van-field>

        <van-field
          v-model="studPyName"
          name="studPyName"
          placeholder="拼音"
          clearable
          :rules="[{ required: true }]"
        >
          <template slot="label">
            <i class="reqire">*</i> 拼音
          </template>
        </van-field>

        <van-field
          v-model="idCard"
          name="idCard"
          placeholder="请输入身份证"
          clearable
          :rules="[{ required: true }]"
          @blur="domainConvert('2')"
        >
          <template slot="label">
            <i class="reqire">*</i> 身份证
          </template>
        </van-field>

        <van-field 
          name="studSex" 
          :rules="[{ required: true }]">
          <template #input>
            <van-radio-group v-model="studSex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="0">女</van-radio>
            </van-radio-group>
          </template>
          
          <template slot="label">
            <i class="reqire">*</i> 性别
          </template>
        </van-field>

        <van-field
          readonly
          clickable
          name="studBirthday"
          :value="studBirthday"
          placeholder="点击选择出生日期"
          :rules="[{ required: true }]"
          @click="showPicker = true"
        >
          <template slot="label">
            <i class="reqire">*</i> 出生日期
          </template>
        </van-field>

        <van-field
          v-model="studCountry"
          name="studCountry"
          placeholder="请输入国家"
          clearable
          :rules="[{ required: true }]"
        >
          <template slot="label">
            <i class="reqire">*</i> 国家
          </template>
        </van-field>

        <van-field
          readonly
          clickable
          name="studNation"
          :value="studNationName"
          placeholder="请选择民族"
          :rules="[{ required: true }]"
          @click="showMzPicker = true"
          >
          <template slot="label">
            <i class="reqire">*</i> 民族
          </template>
        </van-field>

        

        <van-field
          readonly
          clickable
          name="artMajor"
          :value="artMajorName"
          placeholder="请选择专业"
          :rules="[{ required: true }]"
          @click="showZyPicker = true"
        >
          <template slot="label">
            <i class="reqire">*</i> 专业
          </template>
        </van-field> 

        <van-field
          readonly
          clickable
          name="artLevel"
          :value="artLevelName"
          placeholder="请选择级别"
          :rules="[{ required: true }]"
          @click="showJbPicker = true"
        >
          <template slot="label">
            <i class="reqire">*</i> 级别
          </template>
        </van-field>

        <van-field name="profilePic" style="padding: 10px 20px" v-model="profilePic" label-width="40px" label=""  required :rules="[{required: true, validator, message: '请上传寸照!' }]">
          <template #input>
           <div style="display: flex; height: 215.5px;">
            <UserHeadPortrait style="flex: 0.8;" v-model="profilePic" />
            <div style="flex: 1.2; margin-left: 10px;">
              <div style="color: #646566;">考生照片</div>
              <div style="color: #7a7a7a; padding-top: 8px;">请上传近期（三个月内）正面免冠彩色半身电子照片，照片背景颜色单一、人像清晰、轮廓分明、神态自然。</div>
              <!-- <div style="color: #7a7a7a;">(413×626像素 )</div> -->
            </div>
          </div>
          </template>
        </van-field>
        <van-field
          style="flex-wrap: wrap;"
          label-width="100%"
          v-model="maxCertLevel"
          label="申报专业已通过最高级（没有填无）"
          name="maxCertLevel"
          placeholder="请输入"
          clearable
        ></van-field>

        <van-field
          style="flex-wrap: wrap;"
          label-width="100%"
          v-model="maxCertNo"
          label="已通过最高级证书编号（没有填无）"
          name="maxCertNo"
          placeholder="请输入"
          clearable
        ></van-field>

        <van-field
          style="flex-wrap: wrap;"
          label-width="100%"
          v-model="maxCertOrgan"
          label="发证机构（没有填无）"
          name="maxCertOrgan"
          placeholder="请输入"
          clearable
        ></van-field>

        <div style="padding: 15px 10px; font-size: 16px; font-weight: 500; background-color:#fafafa;">考生联系方式</div>

        <van-field
          v-model="relateName"
          label="联系人姓名"
          name="relateName"
          placeholder="请输入联系人姓名"
          clearable
        ></van-field>

        <van-field
          v-model="relateShip"
          label="与考生关系"
          name="relateShip"
          placeholder="请输入与考生关系"
          clearable
        ></van-field>

        <van-field
          v-model="phone"
          name="phone"
          placeholder="请输入联系电话"
          clearable
          :rules="[{ required: true }]"
        >
          <template slot="label">
            <i class="reqire">*</i> 联系电话
          </template>
        </van-field>

        <van-field
          v-model="address"
          label="地址"
          type="textarea"
          :rows="2"
          name="address"
          placeholder="请输入地址"
          clearable
        ></van-field>

        

        <div style="margin: 45px 16px 16px;">
          <van-button :loading="loading" loading-type="spinner" round block type="info" native-type="submit">提交</van-button>
        </div>


      </van-form>

      <van-popup v-model="showMzPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onMzConfirm"
          @cancel="showMzPicker = false"
        />
      </van-popup>

      <van-popup v-model="showZyPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="zycolumns"
          @confirm="onZyConfirm"
          @cancel="showZyPicker = false"
        />
      </van-popup>

      <van-popup v-model="showJbPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="jbcolumns"
          @confirm="onJbConfirm"
          @cancel="showJbPicker = false"
        />
      </van-popup>
      

      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
    </div>
    <img  :src="require('@/assets/footer.png')" width="100%" class="footer-img" alt=""> 
  </div>
</template>
<script>
import { Form, Field, Button, RadioGroup, Radio, DatetimePicker, Popup, Picker,Dialog, Uploader } from 'vant';
import { Collapse, CollapseItem, Toast } from 'vant';
import { userDetail, studentCreate, systemDicts, domainConvert } from '@/api'
import UserHeadPortrait from '@/view/components/userHeadPortrait'
export default {
  components: {
    UserHeadPortrait,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Dialog.Component.name]: Dialog.Component,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Uploader.name]: Uploader,
    [Toast.name]: Toast
  },
  data() {
    return {
      active: ['1'],
      orgCode: '',
      userName: '',
      periodDesc: '',
      applyTime: '',
      loading: false,
      showPicker: false,
      showMzPicker: false,
      showZyPicker: false,
      showJbPicker: false,
      columns: [],
      zycolumns: [],
      jbcolumns: [],
      minDate: new Date(1972, 1, 1),
      maxDate: new Date(2025, 10, 1),
      studName: '',
      studPyName: '',
      studSex: '',
      studBirthday: '',
      studCountry: '',
      studNation: '',
      studNationName: '',
      idCard: '',
      artMajor: '',
      artMajorName: '',
      artLevel: '',
      artLevelName: '',
      phone: '',
      profilePic: '',
      maxCertLevel: '',
      maxCertNo: '',
      maxCertOrgan: '',
      relateName: '',
      relateShip: '',
      address: ''
    }
  },
  created() {
    this.systemDicts()
    this.orgCode = this.$route.query.orgCode
    if (!this.orgCode) {
      Dialog.alert({
        title: '标题',
        message: '无机构码，不能提交报名，请联系管理员！',
      }).then(() => {});
      return
    } else {
      this.userDetail()
    }
  },
  methods:{
    validator() {
      if (this.profilePic) {
        return true
      } else {
        Toast('请上传寸照！')
        return false
      }

    },
    domainConvert(type) {
      const params = {
        type
      }
      if (type === '1') {
        if (!this.studName) return
        params.key = this.studName
        this.studPyName = ''
      } else {
        if (!this.idCard) return
        params.key = this.idCard
        this.studSex = ''
        this.studBirthday = ''
      }
      domainConvert(params).then(res => {
        if (type === '1') {
          this.studPyName = res.resultData.pinyin
        } else {
          this.studSex = res.resultData.sex
          this.studBirthday = res.resultData.birthDay
        }
      }).catch(() => {})
    },
    systemDicts() {
      systemDicts({
        types: '1,2,3'
      }).then(res => {
        this.columns = res.resultData['2'].map(item => {
          return{
            text: item.dictName,
            value: item.dictValue
          }
        })
        this.zycolumns = res.resultData['1'].map(item => {
          return{
            text: item.dictName,
            value: item.dictValue
          }
        })
        this.jbcolumns = res.resultData['3'].map(item => {
          return{
            text: item.dictName,
            value: item.dictValue
          }
        })
      }).catch(() => {})
    },  
    userDetail() {
      userDetail({userCode: this.orgCode}).then(res => {
        if (res.resultData) {
          this.userName = res.resultData.userName
          this.applyTime = res.resultData.startTime + '-' + res.resultData.endTime
          this.periodDesc = res.resultData.periodDesc 
          if(res.resultData.artStatus === 0) {
            Dialog.alert({
              title: '报名未开始',
              message: '报名开始时间：' + res.resultData.startTime,
              showConfirmButton: false,
              className: 'my-dialog'
            }).then(() => {}); 
          } else if (res.resultData.artStatus === 2) {
            Dialog.alert({
              title: '报名已结束',
              message: '报名截止时间：' + res.resultData.endTime,
              showConfirmButton: false,
              className: 'my-dialog'
            }).then(() => {});
          }
        }
      }).catch(() => { })
    },
    onSubmit(data) {
      const params = {
        ...data,
        studNation: this.studNation,
        artMajor: this.artMajor,
        artLevel: this.artLevel,
        organCode: this.orgCode
      }
      this.loading = true
      studentCreate(params).then((res) => {
        this.loading = false
        this.$router.replace({path: '/applySuccess', query: {stuId: res.resultData.id}})
        this.$refs.vanForm.resetValidation(['studName','studPyName','studSex','studBirthday','studCountry','studNation','idCard','artMajor','artLevel','phone',])
      }).catch(() => {
        this.loading = false
      })
    },
    formatter(type, val) {
      if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      } else if (type === 'year') {
        return `${val}年`
      }
      return val;
    },
    onConfirm(date) {
      const month = date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
      const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
      this.studBirthday = `${date.getYear() + 1900}-${month}-${day}`;
      this.showPicker = false;
    },
    onMzConfirm(value) {
      this.studNationName = value.text;
      this.studNation = value.value
      this.showMzPicker = false;
    },
    onZyConfirm(value) {
      this.artMajor = value.value;
      this.artMajorName = value.text
      this.showZyPicker = false;
    },
    onJbConfirm(value) {
      this.artLevel = value.value;
      this.artLevelName = value.text
      this.showJbPicker = false;
    }
  }
}
</script>
<style lang="less">
.text-list-war {
  .van-cell__title {
    flex: none;
  }
}
.van-cell {
  font-size: 15px;
}
.my-dialog {
  .van-dialog__header {
    color: red
  }
}
</style>
<style lang="less" scoped>
.main-box-war {
  height: 100%;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
}
.text-list-war {
  // position: relative;
  // flex: 1;
  // height: 100%;
  padding: 10px;
  // overflow-y: auto;
  // transform: translateZ(0);
  .head {
    font-size: 25px;
    text-align: center;
    padding: 10px;
    margin: 20px 0 20px;
  }
  .sub-head {
    font-size: 15px;
    color: #ccc;
  }
  .reqire {
    color: #ee0a24;
    font-size: 14px;
  }
  .head-img {
    vertical-align: middle;
    margin-right: 15px;
  }
}
.footer-img {
  padding: 20px 80px;
}
</style>